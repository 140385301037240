<template>
  <f-card>
    <div v-if="loading">
      <v-progress-linear color="grey" indeterminate height="12" />
    </div>
    <div v-else>
      <v-data-table :headers="headers" :items="users" @click:row="edit" />
    </div>
  </f-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: this.$t('pages.users.list.email'),
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: this.$t('pages.users.list.createdAt'),
          value: "created_at",
          align: "end",
        },
      ],
    }
  },
  computed: {
    users() {
      return this.$store.state.user.list;
    },
    loading() {
      return !this.$store.state.system.loading.done;
    },
  },
  methods: {
    edit(user) {
      this.$router.push({
        name: "user-edit",
        params: {
          id: user.id,
        },
      });
    },
  },
};
</script>
