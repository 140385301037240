import UserList from '../../components/users/List.vue'

export default {
  data: () => ({
    permissions: {
      read: true,
      write: true
    }
  }),
  components: {
    UserList
  },
  created() {
    this.permissions = this.$getPermissions()
    this.list()
  },
  methods: {
    async list() {
      this.$store.dispatch('system/loading', 'data')
      await this.$store.dispatch('user/list')
      this.$store.dispatch('system/loading', 'done')
    },
    create() {
      this.$router.push({
        name: 'user-create'
      })
    }
  },
  destroyed() {
    this.$store.dispatch('user/clear', 'list')
  }
}
